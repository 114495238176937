<template>
	<div>
		<div style="position:relative;">
		<input type="text" class="form-control form-control-sm" placeholder="Email" v-model="userexternalemail" maxlength="70" @input="emailexists(userexternalemail)">
		<i v-if="working" class="fa fa-circle-o-notch fa-spin" style="font-size: 12px;position:absolute; margin-top: -6px; top:50%; right: 10px;"/>
		</div>
        <span class="text-danger" v-if="errormessage == 'emailinvalid'"><small>Email is invalid</small></span>
        <span class="text-warning" v-if="suggestuser==true">Email used by an existing Flowe user, name is replaced. Cancel to revert.</span>
        <span class="text-danger" v-if="userexists==true">A contact with this email address has already been added to the list.</span>
	</div>
</template>

<script type="text/javascript"></script>

<script>

	import Usersapi from '@/services/api/users'

	export default {
		name: 'emailField',
		data(){
			return{
				emailexistsTimer:null,
				userexternalemail:"",
				errormessage:"",
				suggestuser:false,
				userexists:false,
				suggesteduser:{},
				working:false
			}
		},
		props:{
			emailvalue: {
				type:String,
				default: ""
			},
			namevalue:{
				type: String,
				default: ""
			},
			users:{
				type: Array,
				default: ""
			}
		},
		computed:{
			userids(){

				let uids = []

				if( this.users ){

					for( let i in this.users ){

						if( this.users[i]._id ){
							uids.push( this.users[i]._id )
						}

					}
				}

				return uids
			},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	
		    
		      token(){
		        return this.$store.getters.token
		      },

		},
		mounted(){

			this.userexternalemail = this.emailvalue
		},

	methods:{

		validateEmail(email) {

    			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    			return re.test(String(email).toLowerCase());
    			
			},	


		emailexists( email ){			

			this.$emit('oninput', email )
			//console.log( object.target.value , "email")

			//if( object.target && object.target.value ){
			//	email = object.target.value
			//}

			let self = this

			let isvalid = this.validateEmail( email )		

			this.userexists = false		

			if( isvalid ){

				this.working = true
				self.$emit('onworking', true )

				if( this.emailexistsTimer ){						
					clearTimeout(this.emailexistsTimer)
					this.emailexistsTimer = null
				}

				this.emailexistsTimer = setTimeout( function(){

					Usersapi.Existsbyemail( email, self.token ).then(response =>{

						if( response.data.ok && response.data.user ){


							if( self.userids && self.userids.length > 0 && self.userids.indexOf(response.data.user._id) > -1 ){

								self.suggestuser = false
								self.userexists = true
								self.$emit('onfail', true )

							}else{

								self.userexists = false

								if( self.namevalue != response.data.user.firstname + " " + response.data.user.lastname ){

									self.suggesteduser = response.data.user 
									self.suggestuser = true
									self.$emit('onsuggest', self.suggesteduser )
									self.$emit('onfail', false )
								}
							}

														

						}else{

							self.userexists = false
							self.suggesteduser = {}
							self.suggestuser = false
							self.$emit('oncancel', self.suggesteduser )
							self.$emit('onfail', false )
						}
						//	console.log( response.data , "userbyemail")

						
					}).catch(error => console.log(error))
					.finally( () => {
						//this.startsearching = true
						self.working = false
						self.$emit('onworking', false )
					})

				}, 300)				
				

			}else{

				this.working = false
				self.$emit('onworking', false )

				if( this.suggestuser == true ){
					this.suggesteduser = {}
					this.suggestuser = false
					this.$emit('oncancel', self.suggesteduser )
				}

				self.$emit('onfail', false )

			}



		}



	},

		unmounted(){
		}

	}
</script>