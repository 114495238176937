<script>
import vSelect from 'vue-select'

export default{
	name:'vueselect',
  	extends: vSelect,
 	methods: {
    	maybeAdjustScroll: () => false
    	//this.$refs['search'] = this.$el.getElementsByTagName('input')[0]
  	}
}
</script>